import gql from "graphql-tag";
import {Query} from "@deity/falcon-ecommerce-uikit";

export const GET_CMS_BLOCK_BY_ID = gql`
  query CMSBlockById($id: Int) {
    cmsBlockById(id: $id) {
        title
        content
        active
    }
  }
`;

export class CMSBlockByIdQuery extends Query {
  static defaultProps = {
    query: GET_CMS_BLOCK_BY_ID,
  };
}
