import React from 'react';
import { withApollo } from 'react-apollo';
import { PRODUCT_LISTING_CONFIG } from '../queries/productListingConfig.gql';

let productListingConfig = null;

export default WrappedComponent => {
  const WithProductListingConfig = props => {

    if (!productListingConfig) {
      const { client } = props;
      const { config } = client.readQuery({ query: PRODUCT_LISTING_CONFIG }) || {};
      productListingConfig = config.productListing;
    }

    return <WrappedComponent {...props} productListingConfig={productListingConfig} />;
  };

  return withApollo(WithProductListingConfig);
};
