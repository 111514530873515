import { useEffect } from "react";
import { trackView } from "../../utils/kiliba";

function KilibaCategoryViewTracker({ categoryId }) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      trackView("category", categoryId);
    }
  }, []);

  return null;
}

export { KilibaCategoryViewTracker };
